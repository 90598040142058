import Highway from '@dogstudio/highway';
import { gsap } from "gsap";

export default class Fade extends Highway.Transition {

    out({ from, trigger, done }) {
        gsap.to(from, {duration: .2, opacity: 0, onComplete:done});
    }

    in({ from, to, trigger, done }) {
        from.remove();
        gsap.fromTo(to, {opacity: 0}, {duration: 0, opacity: 1, onComplete:done});

    }

}
