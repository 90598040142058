import Highway from '@dogstudio/highway';

export default class HomeRenderer extends Highway.Renderer {
    // Hooks/methods
    onEnter() {

    }
    onLeave() {

    }
    onEnterCompleted() {

    }
    onLeaveCompleted() {

    }
}
